jQuery(document).ready(function () {

/*-------------------------------------------------*/
/*  Animated content
/*-------------------------------------------------*/

    wow = new WOW(
        {
            animateClass: 'animated',
            offset:       100
        }
    );

    wow.init();

/*----------------------------------------------------*/
/*  Animated Count To
/*----------------------------------------------------*/

    jQuery('.fun-wrap .fun-box').each(function () {
        jQuery(this).fappear(function() {
            jQuery('.fun').countTo();
        }); 
    });    


});

/*----------------------------------------------------*/
/*  Affichage des alertes
/*----------------------------------------------------*/
$('div.form-alert').delay(5000).slideUp(300);
$('div.alert').not('.alert-important').delay(5000).slideUp(300);